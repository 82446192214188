export const siteName = "Gourmet Garage";
export const siteNameUpper = "GOURMET GARAGE";

export const siteMailList = [
  {
    mail: "online@gourmetgarage.com.tr",
    subject: "Bize Mail Bırakın",
  },
];

export const sitePhoneList = [
  { phone: "0 (850) 622 75 00", info: "" },

];

export const eklenenMiktarKatsayisi = 0.1;
